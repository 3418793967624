const projectId = 'md-live-test'
const functionRegion = 'europe-west2'

export default {
  projectId,
  functionRegion,
  apiKey: 'AIzaSyDrZt58e3FPkcQtJgiK-oCAPosGvwy-6Cw',
  authDomain: `${projectId}.firebaseapp.com`,
  storageBucket: `${projectId}.appspot.com`,
  functionsUrl: `https://${functionRegion}-${projectId}.cloudfunctions.net`,
  hostingUrl: `https://${projectId}.web.app`,
}
